import { graphql } from 'gatsby'
import React from 'react'
import PageLayout from '../components/layout/PageLayout'

const PageTemplate = ({ data }) => {
  return (
      <>
        <PageLayout data={data.contentfulPage}/>
      </>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      pageName
      slug
      pageType
      displayTitle
      pageLayout
      hideGlobalSidebarContent
      navigation {
          ...navItem
      }
      heroImages {
        fluid(maxWidth:1920, quality: 100) {
          ...GatsbyContentfulFluid
        }
        description
        title
      }
      pageSections {
        ...pageSection
      }
      bottomPageSections {
        ...pageSection
      }
      seo {
        ...seo
      }
    }
  }
`

export default PageTemplate
